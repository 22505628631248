@import '../reset.css';
@import '../custom-animate.css';
@import '../../fonts/general-sans/general-sans.css';
@import '../top-navigation.css';
@import '../navigation-dropdown.css';
@import '../navigation-drawer.css';
@import '../testimonial-slider.css';
@import '../footer.css';
@import '../cookies.css';

@import '../globals.css';
@import '../typography.css';

/* 
@media (min-width: 992px) {

}

@media (min-width: 1200px) {

} 
*/

/* #region === Hero Section === */
.hero-section {
  position: relative;
}
.hero-section__inner {
  padding: 56px 24px;
  max-width: 1440px;
  margin: auto;
}

.hero-top-information {
  /* padding: 0 72px; */
}

.preHeading {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  background: var(--success-100);
  border-radius: 36px;
  padding: 8px 12px;

  color: var(--success-500);
  /* Body Small/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

.heroContentGroup {
  margin-top: 8px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
}
.heroHeading {
  max-width: 675px;
  color: var(--neutral-100);

  /* Landing Page/Heading/H3 */
  font-family: 'General Sans', sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.heroHeading .placeCategories {
  position: relative;
  overflow: hidden;
  height: 60px;
  display: grid;
  flex-direction: column;
  color: var(--success-400);
}

.heroHeading .placeCategories .animated-text {
  position: relative;
  animation: slide 20s infinite;
  display: inline-flex;
  flex-direction: column;
}

@keyframes slide {
  0%,
  10% {
    top: 0px;
    max-width: 212px;
  }
  15%,
  25% {
    top: -60px;
    max-width: 105px;
  }
  30%,
  40% {
    top: -120px;
    max-width: 75px;
  }
  45%,
  55% {
    top: -180px;
    max-width: 92px;
  }
  60%,
  70% {
    top: -240px;
    max-width: 65px;
  }
  75%,
  85% {
    top: -300px;
    max-width: 90px;
  }
  90%,
  100% {
    top: -360px;
    max-width: 212px;
  }
}

.heroDescription {
  max-width: 420px;
  color: var(--neutral-60);

  /* Body Medium/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.btn.get-started {
  border-radius: 36px;
  background: var(--primary-main);

  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 12px;

  color: var(--Neutral-10, #fff);

  /* Body Medium/Semibold */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon.w-24 {
  width: 24px;
  height: 24px;
}

.hero-section .rectangle-bg-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}
/* #endregion */

/* #region Hero Illustration */
.hero-illustration {
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  min-height: 573px;
  display: flex;
  align-items: end;
  gap: 24px;
  margin-top: 56px;

  flex-direction: column;
}
.hero-illustration__inner {
  display: flex;
  align-items: end;
  gap: 24px;
}
.hero-illustration .hero-illustration-flowers {
  margin-bottom: 10px;
}
.hero-illustration .hero-illustration-phone {
  margin-bottom: 0px;
}

@media (min-width: 992px) {
  .hero-section__inner {
    padding: 96px 32px 46px;
    max-width: 1440px;
    margin: auto;
  }

  .heroHeading {
    max-width: 675px;
    color: var(--neutral-100);

    /* Landing Page/Heading/H1 */
    font-size: 56px;
  }
  .heroHeading .placeCategories {
    height: 84px;
  }

  .hero-illustration {
    left: auto;
    width: 100%;
    min-height: auto;
    margin-top: 0;
    flex-direction: row;
  }
  .hero-illustration .hero-illustration-flowers {
    margin-bottom: 12px;
  }
  .hero-illustration .hero-illustration-phone {
    margin-bottom: 18px;
  }

  @keyframes slide {
    0%,
    10% {
      top: 0px;
      max-width: 300px;
    }
    15%,
    25% {
      top: -84px;
      max-width: 145px;
    }
    30%,
    40% {
      top: -168px;
      max-width: 105px;
    }
    45%,
    55% {
      top: -252px;
      max-width: 128px;
    }
    60%,
    70% {
      top: -336px;
      max-width: 90px;
    }
    75%,
    85% {
      top: -420px;
      max-width: 126px;
    }
    90%,
    100% {
      top: -504px;
      max-width: 300px;
    }
  }
}

@media (min-width: 1200px) {
  .hero-section__inner {
    padding: 96px 48px 68px;
    max-width: 1440px;
    margin: auto;
  }

  .hero-top-information {
    padding: 0 72px;
  }

  .heroContentGroup {
    align-items: end;
    flex-direction: row;
  }

  .heroHeading {
    max-width: 675px;
    color: var(--neutral-100);

    /* Landing Page/Heading/H1 */
    font-size: 56px;
  }

  .heroDescription {
    margin-bottom: 12px;
    max-width: 344px;

    /* Body Large/Medium */
    font-size: 18px;
  }

  .hero-illustration {
    align-items: flex-end;
    max-height: 568px;
  }
  .hero-illustration .hero-illustration-flowers {
    margin-bottom: 18px;
  }
  .hero-illustration .hero-illustration-phone {
    margin-bottom: 27px;
  }
}
/* #endregion */

/* #region --- Trusted By --- */
.trusted-by {
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(251deg, #1c336b -2.73%, #1e2432 102.73%);
}
.trusted-by-inner {
  padding: 56px 24px 40px;
  max-width: 1440px;
  margin: auto;
}
.headingTrustedBy {
  color: var(--neutral-10);
  text-align: center;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.client-logos {
  display: flex;
}
/* .client-logos:hover .client-logos-slider {
  animation-play-state: paused;
} */
.client-logos-slider {
  display: flex !important;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  /* display: grid !important; */
  margin-top: 24px;
  /* grid-template-columns: repeat(2, 1fr); */
  row-gap: 16px;
  /* min-width: 327px; */
  /* animation: 30s slideHorizontalInfinite infinite linear; */
}
/* .client-logos-slider:nth-child(5) {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
} */

.client-logos-slider img {
  overflow: visible;
  max-width: 163px;
  height: 72px;
  object-fit: cover;
  filter: grayscale(100%);
}
.client-logos-slider img.max-w-327 {
  max-width: 327px;
}
.client-logos-slider img.no-filters {
  filter: none;
}

/* .client-logos-slider img:nth-child(9),
.client-logos-slider img:nth-child(10) {
  display: none;
} */

@keyframes slideHorizontalInfinite {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-500%);
  }
}
@media (min-width: 476px) {
  .client-logos-slider img.max-w-327 {
    max-width: 163px;
  }
}

@media (min-width: 992px) {
  .trusted-by-inner {
    padding: 80px 32px;
  }

  .client-logos-slider {
    row-gap: 24px;
    /* grid-template-columns: repeat(5, 1fr); */
    /* min-width: 960px; */
    /* animation: 60s slideHorizontalInfinite infinite linear; */
  }
  .client-logos-slider img {
    /* flex: 0 1 192px; */
    max-width: 192px;
  }
  .client-logos-slider img.max-w-327 {
    max-width: 192px;
  }
}

@media (min-width: 1200px) {
  .trusted-by-inner {
    padding: 80px 120px;
  }
  .client-logos-slider {
    /* grid-template-columns: repeat(5, 1fr); */
    /* min-width: 1200px; */
  }
  .client-logos-slider img {
    /* flex: 0 1 240px; */
    max-width: 240px;
  }
  .client-logos-slider img.max-w-327 {
    max-width: 240px;
  }
  /* .client-logos-slider img:nth-child(9),
  .client-logos-slider img:nth-child(10) {
    display: block;
  } */
}
/* #endregion */

/* #region --- Feature Section --- */
.feature-section {
  position: relative;
  overflow: hidden;
  margin-top: -1px;
}
.feature-section .rectangle-bg-top {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.feature-section-inner {
  /* position: relative; */
  max-width: 1440px;
  margin: auto;
  padding: 152px 24px 32px;
}
.feature-section__content {
  max-width: 996px;
  margin: 32px auto 0;
}
.feature-section .ornament-rectangle-top-right {
  max-width: 351px;
  position: absolute;
  top: 0;
  right: -48px;
}
.preheadingFeature {
  color: var(--success-400);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}
.headingFeature {
  color: var(--neutral-100);
  text-align: center;
  max-width: 792px;
  margin: 16px auto;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}

.select-group-feature {
  /* margin: 32px 0 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  /* height: 56px; */
  /* border-bottom: 2px solid var(--primary-main); */
}
.select-group-feature:nth-child(4) {
  margin-bottom: 0;
}
.select-group-feature .btn-select-feature {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: var(--neutral-50);
  height: 56px;
  border-bottom: 2px solid var(--neutral-30);

  /* Body Large/Medium */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.select-group-feature.active .btn-select-feature {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  color: var(--neutral-100);
  height: 56px;
  border-bottom: 2px solid var(--primary-main);
}
.select-group-feature .select__arrow-icon {
  rotate: 180deg;
  stroke: var(--neutral-50);
}
.select-group-feature.active .select__arrow-icon {
  rotate: 0deg;
  stroke: var(--neutral-100);
}

.tab-group {
  position: relative;
  display: none;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0;
  border-bottom: 1px solid var(--neutral-30);
}
.btn.tab-feature {
  height: 56px;
  padding: 0 24px;

  color: var(--neutral-50);
  /* Body Large/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}
.btn.tab-feature.active {
  border-bottom: 2px solid var(--Primary-Main, #05a6f0);

  color: var(--neutral-100);
}

.features__illustration {
  display: none;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 322px;
}
.feature-image-mobile {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 322px;
}
.feature-image-mobile img,
.features__illustration img {
  position: absolute;
  object-fit: cover;
}

.feature-points {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}
.features__illustration ~ .feature-points[data-tab='digital-transformation'] {
  display: none;
}
.feature-points[data-tab='intelligent-finance'],
.feature-points[data-tab='people-management'],
.feature-points[data-tab='analytics'] {
  display: none;
}
.feature-point {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.feature-point .icon-feature-point {
  width: 34px;
  height: 34px;
}
.feature-point .title-feature-point {
  color: #021624;

  /* Body Medium/Semibold */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.feature-point .desc-feature-point {
  color: var(--neutral-60);

  /* Body Small/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

@media (min-width: 992px) {
  .feature-section-inner {
    padding: 120px 32px;
  }
  .feature-section .ornament-rectangle-top-right {
    right: 32px;
  }
  .select-group-feature {
    display: none;
  }
  .tab-group {
    display: flex;
  }
  .features__illustration {
    display: block;
  }
  .feature-points {
    display: grid;
    margin-top: 32px;
    grid-template-columns: repeat(3, 1fr);
  }
  .features__illustration ~ .feature-points[data-tab='digital-transformation'] {
    display: grid;
  }
}

@media (min-width: 1200px) {
  .feature-section-inner {
    padding: 120px;
  }
  .feature-section .ornament-rectangle-top-right {
    right: 56px;
  }

  .feature-points[data-tab='people-management'] {
    grid-template-columns: repeat(5, 1fr);
  }
}
/* #endregion */

/* #region --- Financial Feature Section --- */
.financial-feature-section {
  position: relative;
  background: linear-gradient(253deg, #1c336b -2.02%, #1e2432 90%);
}
.financial-feature-section__rectangle-top {
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 2;
}
.financial-feature-section__rectangle-top svg {
  min-width: 1440px;
}
.preHeadingFinancialFeature {
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--success-400);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}
.preHeadingFinancialFeature .point {
  flex: none;
  display: block;
  border-radius: 32px;
  background: var(--success-400);
  width: 32px;
  height: 32px;
}
.headingFinancialFeature {
  margin-bottom: 24px;
  color: var(--neutral-10);
  max-width: 487px;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.descFinancialFeature {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 48px;
  max-width: 487px;

  /* Body Medium/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.captionBottom {
  color: var(--neutral-10);

  /* Landing Page/Heading/H6 */
  font-family: 'General Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.gradient-background-financial-feature {
  pointer-events: none;
  max-width: 100%;
  right: 0px;
  top: 0px;
  position: absolute;
}
.gradient-background-financial-feature.small {
  display: block;
}
.gradient-background-financial-feature.large {
  display: none;
}
.financial-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 120px 120px 192px; */
  max-width: 1440px;
  margin: auto;
  z-index: 1;
}
.financial-feature__logo {
  height: 40px;
  width: 40px;
}
.financial-feature_title {
  color: var(--Neutral-10, #fff);
  max-width: 384px;

  /* Landing Page/Heading/H6 */
  font-family: 'General Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.financial-feature_description {
  color: rgba(255, 255, 255, 0.6);
  max-width: 384px;

  /* Body Small/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.left-content {
  padding: 120px 24px 48px;
  width: 100%;
  /* max-width: 488px; */
}
.financial-feature_points {
  padding: 48px 24px 56px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  /* max-width: 384px; */
  /* margin-right: 102px; */
}
.financial-feature_point {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.financial-feature_content {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.customer-story {
  position: relative;
  z-index: 1;
  background: white;
  max-height: 640px;
  min-height: 640px;
}
.financial-feature-section__rectangle-bottom {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.financial-feature-section__rectangle-bottom img {
}
.financial-feature-section__rectangle-bottom img.small {
  display: block;
}
.financial-feature-section__rectangle-bottom img.large {
  display: none;
  min-width: 1440px;
}
.customer-story__inner {
  top: -8px;
  position: relative;
  max-width: 327px;
  margin: auto;
  border-radius: 24px;
  /* background: var(--neutral-80); */
  background-color: #1e2432;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.customer-story__image {
  position: relative;
  min-height: 590px;
}
.customer-story__image img {
  position: absolute;
  object-fit: cover;
}
.customer-story__image img.small {
  display: block;
}
.customer-story__image img.large {
  display: none;
}
.customer-story-content-container {
  position: absolute;
  bottom: 0;
}
.customer-story__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 80px 16px 32px;
  position: relative;
}
.customer-story__logo {
  display: flex;
  position: absolute;
  top: 24px;
  left: 24px;
}
.customer-story__logo .wilton-logo {
  display: flex;
  z-index: 2;
  width: 70px;
  height: 100%;
}
.customer-story__logo .wenodo-logo {
  display: flex;
  margin-left: -14px;
  z-index: 1;
  width: 44px;
}
.customer-story__content-bg {
  position: absolute;
  bottom: 0;
  z-index: 0;
}
.customer-story__content-bg.small {
  display: block;
}
.customer-story__content-bg.large {
  display: none;
}
.customer-story-content-quote {
  display: block;
  position: absolute;
  z-index: 1;
  top: -22px;
  right: 20px;
}
.customer-story__heading {
  display: flex;
}
.customer-story__title {
  color: var(--neutral-10);

  /* Body Small/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.customer-story__name_desktop,
.customer-story__name_mobile {
  color: var(--neutral-10);

  /* Body Small/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.customer-story__name_desktop {
  display: none;
}
.customer-story__name_mobile {
  display: block;
}
.customer-story__description {
  color: var(--neutral-10);

  /* Body Large/Regular */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

@media (min-width: 992px) {
  .descFinancialFeature {
    margin-bottom: 92px;
  }
  .financial-content {
    flex-direction: row;
  }
  .left-content {
    padding: 120px 40px 198px 32px;
    width: 50%;
    /* max-width: 488px; */
  }
  .financial-feature_points {
    padding: 120px 32px 198px 40px;
    position: static;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50%;
    /* max-width: 384px; */
    /* margin-right: 102px; */
  }
  .gradient-background-financial-feature {
    max-width: 50%;
    max-height: 680px;
  }
  .gradient-background-financial-feature.small {
    display: none;
  }
  .gradient-background-financial-feature.large {
    display: block;
  }
  .customer-story-content-quote {
    display: block;
    position: absolute;
    z-index: 1;
    top: -52px;
    right: 60px;
  }

  .customer-story {
    max-height: 370px;
    min-height: auto;
  }
  .financial-feature-section__rectangle-bottom img.small {
    display: none;
  }
  .financial-feature-section__rectangle-bottom img.large {
    display: block;
  }

  .customer-story__inner {
    top: -134px;
    max-width: 800px;
  }
  .customer-story__content {
    gap: 16px;
    padding: 64px 24px 20px;
  }
  .customer-story__image {
    min-height: 488px;
  }
  .customer-story__image img {
    position: relative;
    object-fit: cover;
  }
  .customer-story__image img.small {
    display: none;
  }
  .customer-story__image img.large {
    display: block;
  }
  .customer-story__content-bg.small {
    display: none;
  }
  .customer-story__content-bg.large {
    display: block;
  }
  .customer-story__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .customer-story__heading_mobile {
    display: none;
  }
  .customer-story__title {
    font-size: 12px;
  }
  .customer-story__name {
    font-size: 12px;
  }
  .customer-story__name_desktop {
    display: block;
  }
  .customer-story__name_mobile {
    display: none;
  }
  .customer-story__description {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .descFinancialFeature {
    margin-bottom: 122px;
  }

  .left-content {
    padding: 120px 114px 192px 120px;
  }
  .financial-feature_points {
    padding: 120px 120px 192px 114px;
  }
  .gradient-background-financial-feature {
    max-height: 714px;
  }

  .financial-feature_title {
    font-size: 20px;
  }

  .customer-story {
    max-height: 440px;
  }
  .customer-story__inner {
    top: -136px;
    max-width: 996px;
  }
  .customer-story__content {
    gap: 20px;
    padding: 64px 32px 24px;
  }
  .customer-story__logo {
    top: 40px;
    left: 32px;
  }
  .customer-story__logo .wilton-logo {
    width: 88px;
  }
  .customer-story__logo .wenodo-logo {
    margin-left: -16px;
    width: 56px;
  }
  .customer-story__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .customer-story__heading_mobile {
    display: none;
  }
  .customer-story__title {
    font-size: 14px;
  }
  .customer-story__name {
    font-size: 14px;
  }
  .customer-story__name_desktop {
    display: block;
  }
  .customer-story__name_mobile {
    display: none;
  }
  .customer-story__description {
    font-size: 18px;
  }
}
/* #endregion */

/* #region --- Section Transform Business --- */
.transformBusiness {
  position: relative;
  background: var(--neutral-10);
}
.transformBusiness__inner {
  max-width: 1440px;
  margin: auto;
  padding: 40px 24px 120px;
}
.transformBusiness__preHeading {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 12px;
  width: fit-content;
  margin: 0 auto 16px;

  color: var(--success-400);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}
.transformBusiness__preHeading-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}
.transformBusiness__title {
  color: var(--neutral-100);
  text-align: center;
  max-width: 616px;
  margin: 0 auto 24px;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.transformBusiness__description {
  color: var(--neutral-60);
  text-align: center;
  max-width: 790px;
  margin: auto;

  /* Body Medium/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.transform-business-image {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
}
.transform-business-image .old-way-transform-business,
.transform-business-image .new-way-transform-business {
  display: flex;
}
.transformBusiness__notes-bottom {
  max-width: 790px;
  margin: auto;

  color: var(--neutral-60);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.transformBusiness__rectangle-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .transformBusiness__inner {
    padding: 48px 32px 120px;
  }
  .transformBusiness__preHeading {
    flex-direction: row;
    text-align: center;
  }
  .transform-business-image {
    gap: 24px;
    flex-direction: row;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

@media (min-width: 1200px) {
  .transformBusiness__inner {
    padding: 80px 120px 120px;
  }
}
/* #endregion */

/* #region --- Services Enhance Profitability --- */
.section__services {
  position: relative;
}
.section__services .ornament-rectangle-top-right {
  max-width: 351px;
  position: absolute;
  top: -28px;
  right: 12px;
}
.services__inner {
  max-width: 1440px;
  margin: auto;
  padding: 40px 24px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}
.section__services:nth-child(2n-1) .services__inner {
  flex-direction: column-reverse;
}
.services__inner--pt-120 {
  padding-top: 80px;
}
.services__inner--pb-120 {
  padding-bottom: 80px;
}
.services__content {
  max-width: 588px;
}
.services__preHeading {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  color: var(--success-400);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}
.services__icon {
  display: flex;
  height: fit-content;
}
.services__heading {
  margin-bottom: 24px;
  color: var(--neutral-100);

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.services__description {
  margin-bottom: 24px;
  color: var(--neutral-60);

  /* Body Medium/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.services__lists {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}
.services__list-item {
  display: flex;
  align-items: start;
  gap: 20px;
  color: var(--neutral-60);

  /* Body Small/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.services__list-item-icon {
  flex: none;
  margin: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 40px;
  background: var(--success-300);
  backdrop-filter: blur(1px);
}
.services__illustration {
  display: flex;
  max-width: 588px;
}

@media (min-width: 992px) {
  .section__services .ornament-rectangle-top-right {
    right: 24px;
    top: -36px;
  }
  .services__inner {
    padding: 80px 32px;

    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 72px;
  }
  .section__services:nth-child(2n-1) .services__inner {
    flex-direction: row;
  }
  .services__inner--pt-120 {
    padding-top: 120px;
  }
  .services__inner--pb-120 {
    padding-bottom: 120px;
  }
  .services__content {
    max-width: 444px;
  }
}

@media (min-width: 1200px) {
  .section__services .ornament-rectangle-top-right {
    right: 56px;
    top: -50px;
  }
  .services__inner {
    padding: 80px 120px;
  }
  .services__inner--pt-120 {
    padding-top: 120px;
  }
  .services__inner--pb-120 {
    padding-bottom: 120px;
  }

  .services__content {
    max-width: 486px;
  }
}
/* #endregion */

/* #region --- Section Testimonial --- */
.section__testimonial {
  display: flex;
  overflow: hidden;
  position: relative;
  background: linear-gradient(253deg, #1c336b -2.02%, #1e2432 100%);
}
.testimonial__inner {
  display: flex;
  gap: 126px;
  max-width: 1440px;
  margin: auto;
  padding: 80px 24px;
  gap: 37px;
  flex-direction: column;
}
.testimonial__bg-rectangle-bottom {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: auto;
}
.testimonial__photo-profile {
  overflow: hidden;
  display: flex;
  flex: none;
  max-width: 486px;
  border-radius: 8px;
  border: 3px solid #fff;
}
.testimonial__quote-label {
  color: var(--primary-main);
  margin-bottom: -30px;
  pointer-events: none;

  /* Mobile/Heading/H1 */
  font-family: Montserrat, sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 72.8px */
  letter-spacing: -0.28px;
}
.testimonial__text {
  margin-bottom: 48px;
  color: var(--neutral-10);

  /* Landing Page/Heading/H5 */
  font-family: 'General Sans', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
}
.testimonial__people-information {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.testimonial__people-name {
  color: var(--Neutral-10, #fff);

  /* Landing Page/Heading/H6 */
  font-family: 'General Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.testimonial__people-job {
  color: var(--Neutral-50, #94a3b8);

  /* Body Medium/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

@media (min-width: 992px) {
  .testimonial__inner {
    gap: 72px;
    padding: 120px 32px;
    flex-direction: row;
  }
  .testimonial__photo-profile {
    max-width: 444px;
    border-radius: 7.5px;
    border: 2.75px solid #fff;
  }
  .testimonial__text {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .testimonial__inner {
    gap: 126px;
    padding: 120px;
  }
  .testimonial__photo-profile {
    max-width: 486px;
    border-radius: 16px;
    border: 3px solid #fff;
  }
  .testimonial__text {
    font-size: 24px;
  }
}
/* #endregion */

/* #region --- Section Product --- */
.section-product__inner {
  max-width: 1440px;
  margin: auto;
  padding: 80px 24px 40px;
}
.product__preHeading {
  display: flex;
  align-items: center;
  gap: 12px;

  color: var(--success-400);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}
.product__heading {
  color: var(--neutral-100);
  margin: 16px 0;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.product__desction-container {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;
}
.product__description {
  color: var(--neutral-60);

  /* Body Medium/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.btn-see-all-product {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 16px;
  min-height: 40px;
  border-radius: 54px;
  border: 1px solid var(--primary-border);
  background: var(--neutral-10);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--primary-main);

  /* Body Small/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}

.section-product__content {
  margin-top: 48px;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 16px;
  flex-direction: column;
}

.product-content__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.product-intelligent-finance {
  overflow: hidden;
  position: relative;
  padding: 24px;
  border-radius: 24px;
  background: var(--primary-border);
}
.product-intelligent-finance__maps-illustration {
  min-width: 841px;
  z-index: 0;
  display: flex;
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
}
.product-intelligent-finance .product-item__card {
  min-width: 139px;
}

.product-app-suite {
  overflow: hidden;
  position: relative;
  padding: 40px 24px;
  border-radius: 24px;
  background: #d2f4df;
}
.product-apps-suite__bg-illustration {
  z-index: 0;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: -45px;
  min-width: 935px;
  pointer-events: none;
}
.product-app-suite .product-item__card {
  min-width: 139px;
}
.product-intelligent-finance .product-item__title,
.product-app-suite .product-item__title {
  color: var(--neutral-100);
}

.product-power-insight {
  padding: 24px;
  border-radius: 24px;
  background: #1e2432;
}
.product-resource .product-item__card,
.product-power-insight .product-item__card {
  min-width: 139px;
}

.product-resource {
  overflow: hidden;
  position: relative;
  padding: 24px;
  border-radius: 24px;
  background: var(--primary-pressed);
}
.product-resource__bg-illustration {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 1458px;
  pointer-events: none;
}
.product-power-insight .product-item__title,
.product-resource .product-item__title {
  color: var(--neutral-10);
}

.header-product-card {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  /* Landing Page/Heading/H5 */
  font-family: 'General Sans', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
}
.header-product-card.black {
  color: var(--neutral-100);
}
.header-product-card.white {
  color: var(--neutral-10);
}

.btn-cta-product {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  border-radius: 54px;
  border: 1px solid var(--primary-border);
  background: var(--neutral-10);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.product-items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 24px;
}
.product-item__card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  padding: 16px 12px;
  min-height: 126px;
}
.product-item__logo {
  width: 40px;
  height: 40px;
}
.product-item__title {
  text-align: center;

  /* Body XSmall/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
}
.product-item__title .block {
  display: block;
}

@media (min-width: 992px) {
  .section-product__inner {
    padding: 120px 32px 80px;
  }
  .product-content__inner {
    gap: 24px;
  }
  .product-content__inner.max-w-846 {
    max-width: 846px;
  }
  .product-content__inner.max-w-330 {
    max-width: 330px;
  }

  .header-product-card {
    font-size: 24px;
  }

  .section-product__content {
    gap: 24px;
    flex-direction: row;
  }

  .btn-cta-product {
    width: 40px;
    height: 40px;
  }

  .product__desction-container {
    align-items: end;
    flex-direction: row;
  }
  .product__description {
    max-width: 707px;
  }
  .btn-see-all-product {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .product-intelligent-finance,
  .product-app-suite,
  .product-resource,
  .product-power-insight {
    padding: 40px 24px;
  }

  .product-app-suite .product-item__card {
    min-width: 110px;
  }
  .product-resource .product-item__card,
  .product-power-insight .product-item__card {
    min-width: 141px;
  }
}

@media (min-width: 1200px) {
  .section-product__inner {
    padding: 120px 120px 80px;
  }
  .product__description {
    max-width: 827px;
  }
  .product-intelligent-finance {
    padding: 40px 80px;
  }
  .product-intelligent-finance__maps-illustration {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .product-intelligent-finance .product-items {
    column-gap: 24px;
  }
  .product-intelligent-finance .product-item__card {
    min-width: 153px;
  }

  .product-app-suite .product-item__card {
    min-width: 146px;
  }

  .product-app-suite {
    padding: 40px 56px;
  }
  .product-apps-suite__bg-illustration {
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
/* #endregion */

/* #region --- Section Services - Committed to Ensuring Your Success --- */
.section__product-growth {
  /* display: none; */
  background: var(--neutral-20);
}
.product-grow__background-gradient {
  /* position: relative; */
  width: 100%;
  min-height: 482px;
}
.product-grow__background-gradient .img {
  overflow: visible;
  position: absolute;
  object-fit: cover;
  height: auto;
  min-height: 100%;
}
.section-product-growth__inner {
  max-width: 1440px;
  margin: auto;
  padding: 72px 24px 80px;
}
.rectangleBottonProductGrow {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.rectangleBottonProductGrow svg {
  height: 100%;
}
.btn-product-growth {
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 2;
}
.btn-product-growth,
.btn-product-testimonial,
.btn-product-about-us {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  padding: 0 16px;
  border-radius: 54px;
  border: 1px solid var(--primary-border);
  background: var(--neutral-10);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.product-growth__about-card {
  border-radius: 24px;
  border: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.03);
  padding: 24px;
}
.product-growth__testimonial {
  border-radius: 24px;
  border: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  padding: 32px 24px;
  max-width: 792px;
}
.product-growth__testimonial-text {
  /* Body Large/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.product-growth__testimonial-img {
  position: relative;
}
.product-growth__testimonial-img .img {
  position: absolute;
  /* object-position: 0 30%; */
}
.product-growth__testimonial-img::after {
  position: absolute;
  width: 46px;
  height: 41px;
  top: -4px;
  right: -4px;
  content: '';
  z-index: 100;
  background-image: url('../../image/quotes-testimonials.png');
  background-size: cover;
  overflow: visible;
  pointer-events: none;
}

.product-enhancments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  position: relative;
  background: var(--neutral-10);
  border-radius: 24px;
  width: 100%;
  gap: 24px;
  overflow: hidden;
}
.information-maps.small {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.information-maps.large {
  display: none;
}
.information-map {
  display: flex;
  flex-direction: column;
}
.information-map__contact-btn {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  gap: 8px;

  position: absolute;
  height: 40px;
  bottom: 15px;
  right: 13px;

  /* Neutral/10 */
  background: #ffffff;

  border: 1px solid #ace1fa;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 54px;

  /* Body Small/Bold */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  /* Primary/Main */
  color: #05a6f0;
}
.tables-illustration {
  position: relative;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  min-height: 320px;
}
.container__happy-customers {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 24px;
  background: var(--warning-400);
  padding: 48px 32px;
  min-height: 320px;
}

.half-phone {
  margin-bottom: -32px;
  flex: none;
  max-width: 230px;
}
.half-phone .small {
  display: block;
}
.half-phone .large {
  display: none;
}

.combined-experience {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--neutral-100);
  border-radius: 24px;
  padding: 40px 32px;
  min-height: 260px;
}

@media (min-width: 768px) {
  .information-maps.small {
    flex-direction: row;
    /* row-gap: 24px; */
    /* max-width: 384px; */
    /* d-flex flex-col row-gap-24 max-w-384 */
  }
}

@media (min-width: 992px) {
  .section-product-growth__inner {
    padding: 120px 32px;
  }

  .product-enhancments {
    padding: 32px;
    flex-direction: row;
    align-items: end;
  }

  .information-maps.small {
    display: none;
  }
  .information-maps.large {
    display: flex;
  }
  .information-map {
    display: flex;
    max-height: 362px;
  }
  .information-map__contact-btn {
    bottom: 18px;
    right: 16px;
  }

  .product-growth__testimonial {
    padding: 32px;
  }

  .container__happy-customers {
    max-width: 282px;
  }

  .product-growth__about-card {
    min-width: 384px;
  }

  .product-growth__testimonial-img .img {
    object-position: center;
  }

  .combined-experience {
    min-width: 384px;
    min-height: 256px;
  }

  .btn-product-growth {
    position: relative;
    bottom: 0;
    left: 0;
  }
  .btn-product-testimonial {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .section-product-growth__inner {
    padding: 120px;
  }

  .product-enhancments {
    padding: 40px;
  }

  .product-growth__testimonial {
    padding: 40px 40px 32px;
  }

  .product-growth__testimonial-text {
    /* Landing Page/Heading/H6 */
    font-family: 'General Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 30px */
  }

  .half-phone {
    margin-bottom: -40px;
    max-width: 260px;
    gap: 58px;
  }

  .half-phone .small {
    display: none;
  }
  .half-phone .large {
    display: block;
  }
}
/* #endregion */

/* #region --- Section Seamless Integration --- */
.section__seamless-integration {
  overflow: hidden;
  background: linear-gradient(253deg, #1c336b -2.02%, #1e2432 100%);
}
.seamles-integration__inner {
  padding: 80px 24px;
  max-width: 1440px;
  margin: auto;
}

.rectangle-bottom__seamlest-integration {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: center;
}
.rectangle-bottom__seamlest-integration .img {
  min-width: 1440px;
}

.swiperSeamlessIntegration {
  margin-top: 40px;
  overflow: visible !important;
}
.swiperSeamlessIntegration.mobile {
  display: block;
}
.swiperSeamlessIntegration.tablet,
.swiperSeamlessIntegration.desktop {
  display: none;
}
.app-integration__content {
  display: flex !important;
  justify-content: space-between;
  gap: 32px;
}

.title-group-integration {
  color: var(--neutral-10);

  /* Body Medium/Semibold */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

/* .app-integration__group:nth-child(2),
.app-integration__group:last-child {
  display: none;
} */

.app-logo-integration__container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  /* max-width: 324px; */
}

.app-logo-integration {
  position: relative;
  width: 92px;
  height: 92px;
  border-radius: 12px;
  /* border: 1.5px solid var(--neutral-80); */
  /* background: rgba(255, 255, 255, 0.05); */
}
.v-large {
  width: 208px;
}

.app-logo-integration img {
  position: absolute;
  object-fit: cover;
}

.app-integration__nav-bullet {
  position: relative !important;
  display: flex;
  align-items: center;
  gap: 12px;
  width: fit-content !important;
  margin: 48px auto;
}
.swiper-pagination-bullet {
  margin: 0 !important;
  width: 10px !important;
  height: 10px !important;
  background: var(--neutral-60) !important;
  border-radius: 1000px !important;
}
.swiper-pagination-bullet-active {
  background: var(--primary-main) !important;
}

.btn.swiper-seamless-integration-button-prev {
  z-index: 10;
  position: absolute;
  left: -10px;
  top: 282px;

  width: 32px;
  height: 32px;
}
.btn.swiper-seamless-integration-button-next {
  z-index: 10;
  position: absolute;
  right: -10px;
  top: 282px;
  width: 32px;
  height: 32px;
}

.seamless-integration-caption-information {
  margin: auto;
  width: fit-content;
  color: var(--neutral-40);
  text-align: center;

  /* Body Small/Regular */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
.seamless-integration-get-in-touch {
  color: var(--primary-main);

  font-weight: 700;
  text-decoration-line: underline;
}

@media (min-width: 992px) {
  .seamles-integration__inner {
    padding: 120px;
  }
  .rectangle-bottom__seamlest-integration .img {
    min-width: auto;
  }

  .swiperSeamlessIntegration {
    margin-top: 48px;
  }
  .swiperSeamlessIntegration.mobile {
    display: none;
  }
  .swiperSeamlessIntegration.tablet {
    display: block;
  }
  .app-integration__content {
    /* margin-top: 48px; */
  }
  .app-integration__content:nth-child(3) {
    justify-content: center;
  }

  .app-integration__group {
    max-width: 324px;
  }
  /* .app-integration__group:nth-child(2) {
    display: block;
  } */

  .btn.swiper-seamless-integration-button-prev {
    left: calc(-32px + -20px);
    top: 50%;
    transform: translate(0%, -50%);
  }
  .btn.swiper-seamless-integration-button-next {
    right: calc(-32px + -20px);
    top: 50%;
    transform: translate(0%, -50%);
  }

  .nav-bullet-item:nth-child(4),
  .nav-bullet-item:nth-child(5) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .swiperSeamlessIntegration.tablet {
    display: none;
  }
  .swiperSeamlessIntegration.desktop {
    display: block;
  }

  /* .app-integration__group:last-child {
    display: block;
  } */
  .app-integration__content {
    gap: 114px;
  }
  .app-integration__content:nth-child(2) {
    justify-content: center;
  }
  .nav-bullet-item:nth-child(3) {
    display: none;
  }
}
/* #endregion */

/* #region --- Section CTA Home --- */
.section-cta__home {
  position: relative;
  background-image: url('../../image/cta-background.webp');
  /* background-size: 250% 250%; */
  background-size: 300% 100%;
  background-position: bottom;
  margin-top: -1px;
}
.rectangle-bottom__section-cta__home {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.rectangle-bottom__section-cta__home svg {
  min-height: 52px;
}
.section-cta__inner__home {
  padding: 80px 24px 104px;
  max-width: 1440px;
  margin: auto;
}

.section-cta__content__home {
}
.section-cta__title__home {
  color: var(--neutral-10);
  text-align: center;
  margin-bottom: 24px;

  /* Landing Page/Heading/H4 */
  font-family: 'General Sans', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 48px */
}
.section-cta__description__home {
  color: var(--neutral-10);
  text-align: center;
  max-width: 486px;
  margin: 0 auto 40px;

  /* Body Medium/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.btn-book-demo__home {
  margin: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0 16px;
  background: var(--neutral-10);
  border-radius: 54px;
}

@media (min-width: 992px) {
  .section-cta__home {
    background-size: 100% 100%;
    background-position: right;
  }
  .section-cta__inner__home {
    padding: 80px 120px;
  }
}

@media (min-width: 1200px) {
  .section-cta__inner__home {
    padding: 120px;
  }
}
/* #endregion */
