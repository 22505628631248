:root {
  --primary-main: #05a6f0;
  --primary-surface: rgba(205, 237, 252, 0.5);
  --primary-border: #ace1fa;
  --primary-hover: #048ac8;
  --primary-pressed: #025378;
  --primary-focus: #cdedfc;

  --neutral-10: #ffffff;
  --neutral-20: #f7f9fb;
  --neutral-30: #e2e8f0;
  --neutral-40: #cbd5e1;
  --neutral-50: #94a3b8;
  --neutral-60: #6b7c94;
  --neutral-70: #475569;
  --neutral-80: #334155;
  --neutral-90: #1e293b;
  --neutral-100: #1e2432;

  --danger-100: #fff5f4;
  --danger-200: #f88c8c;
  --danger-300: #f56666;
  --danger-400: #f34040;
  --danger-500: #d92d20;

  --info-100: #f0f8ff;
  --info-200: #89b3f7;
  --info-300: #6299f5;
  --info-400: #3b80f2;
  --info-500: #1570ef;

  --success-100: #e6ffee;
  --success-200: #77df9e;
  --success-300: #4ad57d;
  --success-400: #1dca5d;
  --success-500: #039855;

  --warning-100: #fff7de;
  --warning-200: #f4d36b;
  --warning-300: #fec84b;
  --warning-400: #f3a515;
  --warning-500: #dc6803;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: "SF Pro Display", BlinkMacSystemFont, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img {
  width: 100%;
  height: 100%;
}

.btn.btn-talk-us {
  display: flex;
  padding: 0px 16px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 46px;
  border: 1px solid var(--Neutral-30, #e2e8f0);

  color: var(--Primary-Main, #05a6f0);

  /* Body Small/Bold */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}

.mobile {
  display: flex;
}
.tablet {
  display: none;
}
.desktop {
  display: none;
}

/* #region === Positioning === */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-48 {
  right: 48;
}
/* #endregion */

/* #region === Layouts === */
.d-flex {
  display: flex;
}
.d-inline {
  display: inline;
}
.d-block {
  display: block;
}
/* #endregion */

.object-cover {
  object-fit: cover;
}

.object-left {
  object-position: left;
}

.overflow-hidden {
  overflow: hidden;
}

.preHeadingText {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 21px */
  text-transform: uppercase;
}

.font-montserrat {
  font-family: Montserrat, sans-serif;
}
.font-general-sans {
  font-family: 'General Sans', sans-serif;
}

/* #region === Font Weight === */
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
/* #endregion */

/* #region === Font Color === */
.text-neutral-10 {
  color: var(--neutral-10);
}
.text-neutral-40 {
  color: var(--neutral-40);
}
.text-neutral-50 {
  color: var(--neutral-50);
}
.text-neutral-60 {
  color: var(--neutral-60);
}
.text-neutral-100 {
  color: var(--neutral-100);
}
.text-success-400 {
  color: var(--success-400);
}
.text-primary-main {
  color: var(--primary-main);
}
/* #endregion */

/* #region === Text Align === */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
/* #endregion */

/* #region === Spacing === */
.p-24 {
  padding: 24px;
}
.p-32 {
  padding: 32px;
}
.p-40 {
  padding: 40px;
}
.py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mb-4 {
  margin-bottom: 4px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-20 {
  margin-bottom: 20px;
}

.mb-22 {
  margin-bottom: 22px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-64 {
  margin-bottom: 64px;
}

.-mr-24 {
  margin-right: -16px;
}
/* #endregion */

/* #region === Bg Color === */
.bg-neutral-10 {
  background: var(--neutral-10);
}
.bg-neutral-30 {
  background: var(--neutral-30);
}
.bg-neutral-100 {
  background: var(--neutral-100);
}
.bg-warning-400 {
  background: var(--warning-400);
}
.bg-primary-main {
  background: var(--primary-main);
}
/* #endregion */

/* #region === Rounded === */
.rounded-24 {
  border-radius: 24px;
}
.rounded-full {
  border-radius: 1000px;
}
/* #endregion */

/* #region === Gap Layout === */
.col-gap-24 {
  column-gap: 24px;
}
.row-gap-24 {
  row-gap: 24px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.gap-8 {
  gap: 8px;
}
.gap-80 {
  gap: 80px;
}
/* #endregion */

/* #region === Flex Layout === */
.flex-none {
  flex: none;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reserve {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.items-stretch {
  align-items: stretch;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: start;
}
.items-end {
  align-items: end;
}
/* #endregion */

.z-1 {
  z-index: 1;
}

/* #region === Sizing === */
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.max-w-40 {
  max-width: 40px;
}
.max-w-48 {
  max-width: 48px;
}
.max-w-108 {
  max-width: 108px;
}
.max-w-174 {
  max-width: 174px;
}
.max-w-209 {
  max-width: 209px;
}
.max-w-225 {
  max-width: 225px;
}
.max-w-243 {
  max-width: 243px;
}
.max-w-260 {
  max-width: 260px;
}
.max-w-277 {
  max-width: 277px;
}
.max-w-282 {
  max-width: 282px;
}
.max-w-311 {
  max-width: 311px;
}
.max-w-384 {
  max-width: 384px;
}
.max-w-486 {
  max-width: 486px;
}
.max-w-386 {
  max-width: 386px;
}
.max-w-744 {
  max-width: 744px;
}
.max-w-792 {
  max-width: 792px;
}

.min-h-97 {
  min-height: 97px;
}

.max-h-48 {
  max-height: 48px;
}
.max-h-97 {
  max-height: 97px;
}
.max-h-101 {
  max-height: 101px;
}

.min-width-384 {
  min-width: 384px;
}

.w-8 {
  width: 8px;
}
.h-8 {
  height: 8px;
}
.h-auto {
  height: auto;
}
.h-fit {
  height: fit-content;
}
/* #endregion */

.pointer {
  cursor: pointer;
}

@media (min-width: 992px) {
  .md-d-block {
    display: block;
  }

  .md-flex-row {
    flex-direction: row;
  }

  .md-max-w-auto {
    max-width: none;
  }
  .md-max-w-174 {
    max-width: 174px;
  }
  .md-max-w-266 {
    max-width: 266px;
  }
  .md-max-w-295 {
    max-width: 295px;
  }
  .md-max-w-374 {
    max-width: 374px;
  }

  .md-min-w-174 {
    min-width: 174px;
  }

  .md-max-h-auto {
    max-height: none;
  }

  .md-gap-18 {
    gap: 18px;
  }
  .md-flex-row {
    flex-direction: row;
  }
  .md-items-end {
    align-items: end;
  }

  .md-mb-32 {
    margin-bottom: 32px;
  }

  .mobile {
    display: none;
  }
  .tablet {
    display: flex;
  }
  .desktop {
    display: none;
  }
}

@media (min-width: 1200px) {
  .lg-gap-80 {
    gap: 80px;
  }

  .lg-max-w-486 {
    max-width: 486px;
  }

  .mobile {
    display: none;
  }
  .tablet {
    display: none;
  }
  .desktop {
    display: flex;
  }
}

.policy-none {
  display: none !important;
}
