:root {
  --animate-delay: 0.1s !important;
}

.animate__animated.animate__delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-delay: calc(var(--animate-delay) * 1);
  animation-delay: calc(var(--animate-delay) * 1);
}
.animate__animated.animate__delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}
.animate__animated.animate__delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}
.animate__animated.animate__delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}
.animate__animated.animate__delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}
.animate__animated.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  -webkit-animation-delay: calc(var(--animate-delay) * 6);
  animation-delay: calc(var(--animate-delay) * 6);
}
.animate__animated.animate__delay-7s {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
  -webkit-animation-delay: calc(var(--animate-delay) * 7);
  animation-delay: calc(var(--animate-delay) * 7);
}
.animate__animated.animate__delay-8s {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  -webkit-animation-delay: calc(var(--animate-delay) * 8);
  animation-delay: calc(var(--animate-delay) * 8);
}
.animate__animated.animate__delay-9s {
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
  -webkit-animation-delay: calc(var(--animate-delay) * 9);
  animation-delay: calc(var(--animate-delay) * 9);
}
.animate__animated.animate__delay-10s {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  -webkit-animation-delay: calc(var(--animate-delay) * 10);
  animation-delay: calc(var(--animate-delay) * 10);
}
.animate__animated.animate__delay-11s {
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
  -webkit-animation-delay: calc(var(--animate-delay) * 11);
  animation-delay: calc(var(--animate-delay) * 11);
}
.animate__animated.animate__delay-12s {
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
  -webkit-animation-delay: calc(var(--animate-delay) * 12);
  animation-delay: calc(var(--animate-delay) * 12);
}
.animate__animated.animate__delay-13s {
  -webkit-animation-delay: 13s;
  animation-delay: 13s;
  -webkit-animation-delay: calc(var(--animate-delay) * 13);
  animation-delay: calc(var(--animate-delay) * 13);
}
.animate__animated.animate__delay-14s {
  -webkit-animation-delay: 14s;
  animation-delay: 14s;
  -webkit-animation-delay: calc(var(--animate-delay) * 14);
  animation-delay: calc(var(--animate-delay) * 14);
}
.animate__animated.animate__delay-15s {
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-delay: calc(var(--animate-delay) * 15);
  animation-delay: calc(var(--animate-delay) * 15);
}
.animate__animated.animate__delay-16s {
  -webkit-animation-delay: 16s;
  animation-delay: 16s;
  -webkit-animation-delay: calc(var(--animate-delay) * 16);
  animation-delay: calc(var(--animate-delay) * 16);
}
.animate__animated.animate__delay-17s {
  -webkit-animation-delay: 17s;
  animation-delay: 17s;
  -webkit-animation-delay: calc(var(--animate-delay) * 17);
  animation-delay: calc(var(--animate-delay) * 17);
}
.animate__animated.animate__delay-18s {
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
  -webkit-animation-delay: calc(var(--animate-delay) * 18);
  animation-delay: calc(var(--animate-delay) * 18);
}
.animate__animated.animate__delay-19s {
  -webkit-animation-delay: 19s;
  animation-delay: 19s;
  -webkit-animation-delay: calc(var(--animate-delay) * 19);
  animation-delay: calc(var(--animate-delay) * 19);
}
.animate__animated.animate__delay-20s {
  -webkit-animation-delay: 20s;
  animation-delay: 20s;
  -webkit-animation-delay: calc(var(--animate-delay) * 20);
  animation-delay: calc(var(--animate-delay) * 20);
}

/* FadeInUp Custom */
@-webkit-keyframes fadeInUpOneTenth {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUpOneTenth {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.animate__fadeInUpOneTenth {
  -webkit-animation-name: fadeInUpOneTenth;
  animation-name: fadeInUpOneTenth;
}
