.text-h4 {
  /* Landing Page/Heading/H4 */
  font-size: 32px;
  font-style: normal;
  line-height: 150%; /* 48px */
}

.text-h5 {
  /* Landing Page/Heading/H5 */
  font-size: 24px;
  font-style: normal;
  line-height: 150%; /* 36px */
}

.text-h6 {
  /* Landing Page/Heading/H6 */
  font-size: 20px;
  font-style: normal;
  line-height: 150%; /* 30px */
}

.text-body-xsmall {
  /* Body XSmall/Medium */
  font-size: 12px;
  font-style: normal;
  line-height: 150%; /* 18px */
}

.text-body-small {
  /* Body Small/Medium */
  font-size: 14px;
  font-style: normal;
  line-height: 150%; /* 21px */
}

.text-body-medium {
  /* Body Medium/Medium */
  font-size: 16px;
  font-style: normal;
  line-height: 150%; /* 24px */
}

.text-body-large {
  /* Body Large/Bold */
  font-size: 18px;
  font-style: normal;
  line-height: 150%;
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
  .lg-text-h6 {
    /* Landing Page/Heading/H6 */
    font-size: 20px;
    font-style: normal;
    line-height: 150%; /* 30px */
  }
}
