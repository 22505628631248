/* Testimonial Slide for Home, Human Resource, Power Insight and All Produdct Page  */

/* #region --- Section Testimonial Slider --- */
.section__testimonial-slider {
  overflow: hidden;
  background: var(--neutral-10);
}
.section__testimonial-slider-inner {
  max-width: 1440px;
  margin: auto;
  padding: 40px 24px;
}
.section__testimonial-heading {
  max-width: 486px;
}
.btn-learn-more {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  padding: 0 16px;
  border-radius: 54px;
  border: 1px solid var(--primary-border);
  background: var(--neutral-10);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.swiper-testimonial {
  overflow: visible !important;
}
.testimonial-cards {
  /* display: grid; */
  /* grid-template-columns: repeat(1, 1fr); */
  margin: 24px 0;
  /* border-bottom: 1px solid var(--neutral-40); */
  /* gap: 32px; */
}

.testimonial-card {
  height: auto !important;
  padding-bottom: 32px;
}

.horizontal-line-bottom-testimonial-card {
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--neutral-40);
}
.horizontal-line-bottom-testimonial-card.large {
  width: calc(100% + 64px);
  left: -32px;
}
.testimonial-card.swiper-slide-active .horizontal-line-bottom-testimonial-card {
  /* bottom: -3px; */
  z-index: 0;
}

.horizontal-line-bottom-active {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: none;
}
.testimonial-card.swiper-slide-active .horizontal-line-bottom-active {
  background: var(--neutral-100);
}
/* .testimonial-card.swiper-slide-active {
  border-bottom: 3px solid var(--neutral-100);
} */
/* .testimonial-card:nth-child(2),
.testimonial-card:nth-child(3) {
  display: none;
} */

.testimonial-card__inner {
  height: 100%;
  width: fit-content;
  border-radius: 24px;
  background: var(--neutral-20);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.testimonial-card__content {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-card .people-name {
}
.testimonial-card .job-title {
}
.testimonial-card .testimonial-content {
  /* Body Large/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;

  margin: 24px 0;
}
.testimonial-card .testimonial-content [data-element='testimonial-text-all'] {
  display: none;
}
.testimonial-card .testimonial-content span.showMoreBtn,
.testimonial-card .testimonial-content span.showLessBtn {
  cursor: pointer;
  color: var(--success-400);

  /* Body Large/Bold */
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.testimonial-card .company-logo.smashing-plates {
  max-width: 54px;
}
.testimonial-card .company-logo.luca {
  max-width: 78px;
}
.testimonial-card .company-logo.sollip {
  max-width: 85px;
}
.testimonial-card .company-logo.wenodo {
  max-width: 108px;
}
.testimonial-card .company-logo.lio {
  max-width: 40px;
}
.testimonial-card .company-logo.ham {
  max-width: 56px;
}
.testimonial-card .company-logo.aline {
  max-width: 103px;
}
.testimonial-card .company-logo.nac {
  max-width: 58px;
}
.testimonial-card .company-logo.creme {
  max-width: 78px;
}

.testimonial-navigation {
  display: flex;
  align-items: center;
  gap: 16px;
}

.testimonial-navigation .navigation-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 24px;
  min-height: 48px;
  background: var(--neutral-20);
  border-radius: 48px;
  stroke: #1e2432;

  color: var(--neutral-100);

  /* Body Small/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.testimonial-navigation .navigation-item.swiper-button-disabled {
  color: var(--neutral-40);
  stroke: #cbd5e1;
}

.testimonial-card__image {
  position: relative;
  width: 100%;
  max-width: 327px;
  min-height: 280px;
  height: 280px;
}
.testimonial-card__image img {
  position: absolute;
  object-fit: cover;
  object-position: bottom;
  overflow: visible;
}
.testimonial-card__image img.small {
  display: block;
}
.testimonial-card__image img.large {
  display: none;
}

@media (min-width: 992px) {
  .section__testimonial-slider-inner {
    padding: 80px 32px;
  }

  .testimonial-cards {
    margin: 48px 0;
  }
  .testimonial-card__inner {
    border-radius: 32px;
    flex-direction: row;
  }
  .testimonial-card__content {
    padding: 40px;
    max-width: 604px;
  }

  .testimonial-card .testimonial-content {
    font-size: 18px;
    margin: 32px 0;
  }

  .testimonial-navigation .navigation-item {
    width: fit-content;
  }

  .testimonial-card__image {
    width: fit-content;
    min-width: 356px;
    max-width: auto;
    min-height: 464px;
    height: 100%;
  }
  .testimonial-card__image img {
    object-position: right;
  }
  .testimonial-card__image img.large {
    display: block;
  }
  .testimonial-card__image img.small {
    display: none;
  }
}

@media (min-width: 1200px) {
  .section__testimonial-slider-inner {
    padding: 80px 120px;
  }

  .testimonial-cards {
    left: -8.25%;
    /* grid-template-columns: repeat(3, 1fr); */
  }
  .testimonial-card {
    width: 100%;
    max-width: 996px;
  }
  .testimonial-card:nth-child(2),
  .testimonial-card:nth-child(3) {
    display: block;
  }

  .testimonial-card__content {
    padding: 40px;
    max-width: 640px;
  }
}
/* #endregion */
